$(".menu-principal-header button, #submenuprincipal-mobile button").on('click',function(){
    alvo = $(this).attr('data-target');
    let estado = $(`#${alvo}`).hasClass('ativa');
    console.log(estado);
    if(estado == true){
        $(`#${alvo}`).removeClass('ativa');
        $(this).removeClass('ativa');
        $('.submenu-hide-principal').removeClass('ativa');
    }else{
        $('.menu-principal-header button').removeClass('ativa');
        $('ul.menu').removeClass('ativa');
        $(this).addClass('ativa');
        $('.submenu-hide-principal').addClass('ativa');
        $(`#${alvo}`).addClass('ativa');
    }
});
$('#botao-acionar-menu-acessibilidade-mobile').on('click',function(){
    $('#submenumobile-acessibilidade').toggleClass('d-none');
})
$('#hamburguer-mobile').on('click',function(){
    $('#submenuprincipal-mobile').toggleClass('d-none');
})