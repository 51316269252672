$("#acionador-busca").on('click',function(){
$("#buscador").toggleClass('ativa');
});

$('.filter-btn').on('click', function() {
    var letter = $(this).data('filter');

    $('.filtravel').hide(); // Esconde todos os itens

    // Filtra os itens que começam com a letra clicada
    var $filteredItems = $('.filtravel h2').filter(function() {
        return $(this).text().charAt(0) === letter;
    }).parent();

    if ($filteredItems.length === 0) {
        // Se não houver nenhum item após o filtro, exibe a mensagem
        $('.no-results-message').text("Não há nenhum nome com essa letra por enquanto, por favor, tente outra").show();
    } else {
        // Caso contrário, mostra os itens correspondentes e esconde a mensagem, se estiver sendo exibida
        $('.no-results-message').hide();
        $filteredItems.show();
    }
});
