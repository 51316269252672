
  try {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    const removerAtivos = (classe) => {
      $(`${classe} .ativo`).removeClass('ativo');
    };

    $('.seletor-de-ano button').on('click', function (ev) {
      ev.preventDefault();
      params.set('ano', $(this).data('valor'));
      window.location.href = window.location.pathname + `?${params.toString()}`;
    });

    $('.seletor-de-mes button').on('click', function (ev) {
      ev.preventDefault();
      params.set('mes', $(this).data('valor'));
      window.location.href = window.location.pathname + `?${params.toString()}`;
    });

    let dataAtual = $('.display-filtro').text();
    datas = dataAtual.split('/');
    console.log(datas);
    $(`[data-valor="${datas[0]}"]`).addClass('ativo');
    $(`[data-valor="${datas[1]}"]`).addClass('ativo');
  } catch (error) {
    console.log(error);
  }

