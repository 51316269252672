import Swiper , {Autoplay, Navigation, Pagination, Scrollbar} from 'swiper';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';

const swiper = new Swiper('.swiper', {
    modules : [Navigation,Autoplay, Scrollbar],
    slidesPerView:1,
    breakpoints: {
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
      }
    },
    speed: 1500,
    direction: 'horizontal',
    disableOnInteraction: true,
    loop: false,
    initialSlide:1,
    autoplay: true,
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
      },

 
});


const swiper2 = new Swiper('.swiper2', {
    modules : [Navigation,Autoplay, Pagination],
    slidesPerView:1,
    speed: 1500,
    direction: 'horizontal',
    disableOnInteraction: true,
    loop: false,
    initialSlide:1,
    autoplay: true,
      pagination: {
        el: '#SwiperContainer2 .swiper-pagination',
        type: 'bullets',
      }
});

